/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Layout from '../components/layout';
import SEO from '../components/seo';

const Privacy: FC = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO article title={t('translations:terms-of-service', 'Terms of Service')} />
      <Container>
        <h1>{t('translations:terms-of-service', 'Terms of Service')}</h1>
        <div dangerouslySetInnerHTML={{ __html: t('cms:terms-of-service') }} />
      </Container>
    </Layout>
  );
};

export default Privacy;
